.custom-modal {
	.modal-body {
		padding: 0px;
	}

	.modal-content {
		border: none;
		border-radius: 0.3rem;
		outline: 0;
		background-color: transparent;
	}

	.close {
		z-index: 1;
		margin-top: 10px !important;
		position: absolute;
		right: 20px;
		font-size: 4.5rem;
		padding: 0px !important;
	}
}
