.not-found {
	flex-flow: row wrap;
	align-items: center;
	.header {
		font-size: 50px;
	}
	.error-code {
		margin-top: -90px;
		font-size: 250px;
		font-weight: bold;
	}
	.error-message {
		font-size: 30px;
	}
	@media (max-width: 580px) {
		.error-code {
			margin-top: -52px;
			font-size: 200px;
		}
	}
	@media (max-width: 480px) {
		.error-code {
			margin-top: -52px;
			font-size: 150px;
		}
	}
	@media (max-width: 380px) {
		.error-message {
			font-size: 20px;
		}
		.error-code {
			margin-top: -7px;
			font-size: 100px;
		}
		.header {
			font-size: 30px;
		}
	}
}
