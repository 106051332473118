#result-grid {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;

	.result-text {
		font-weight: 600;
		font-size: 18px;
	}

	h4 {
		width: 100%;
		font-size: 14px;
	}
}
