.App button {
	outline: 0;
	box-shadow: none;
	font-weight: 600;
	font-size: 14px;
	cursor: pointer;

	&:hover,
	&:active {
		box-shadow: none;
	}

	&.icon-button {
		i {
			margin-right: 5px;
		}
	}
}

button.button-primary {
	color: white;
	padding: 15px;
	/* TODO: color variables */
	background-color: #eb0029;
	border: none;
	border-radius: 50px;
	width: 100%;

	&:hover {
		border: none;
		background-color: #eb0029;
	}

	&:active {
		background-color: #eb0029 !important;
		border: none;
	}
}

button.button-secondary {
	color: #eb0029;
	padding: 15px;
	background-color: white;
	border: solid #eb0029;
	border-radius: 50px;
	width: 100%;

	&:hover {
		background-color: #eb0029;
		color: white;
	}
}

.App button.btn-primary {
	outline: 0 !important;
	box-shadow: none !important;
	background: #ea1c2d;
	border-radius: 20px;
	border: none;
	width: 140px;
	height: 38px;
	margin-top: 30px;

	&:active,
	&:hover {
		background-color: #eb0029 !important;
		border: none;
	}

	&.inactive {
		color: #fff;
		background-color: #6c757d;

		&:active,
		&:hover {
			background-color: #6c757d !important;
			border: none;
		}
	}
}
