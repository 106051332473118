/* Spinner */
#spinner-container {
	height: calc(100vh - 140px);
	display: flex;
	align-items: center;
	justify-content: center;

	.spinner-border {
		width: 5rem;
		height: 5rem;
		color: #eb0029;
		border: 0.4em solid currentColor;
		border-right-color: transparent;
	}
}
