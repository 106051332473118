// Datepicker
.App .heJwJj,
.gKyapV {
	background-color: #eb0029;
}

.App .dsKVpZ,
.App .dyHccB:hover {
	color: #eb0029;
}

.App .custom-datepicker {
	background: #ea1c2d;
	border-radius: 50px;
	border: none;
	min-width: 250px;
	height: 50px;
	cursor: pointer;
	color: white;
	font-weight: bold;
	outline: none;
	padding: 0 20px;
	text-align: center;

	&::placeholder,
	&::-moz-placeholder {
		color: white;
		text-align: center;
		opacity: 1;
	}

	&.white-scheme {
		background-color: white;
		color: #ea1c2d;
		text-align: center;
		border: solid;
		height: 40px;
		margin-top: 5px;

		&::placeholder {
			color: #ea1c2d;
		}
	}
}
