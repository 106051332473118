.pagination {
	margin-top: 30px;
	width: 100%;
	justify-content: center;
	align-items: center;

	> span {
		color: #ea1c2d;
		font-size: 26px;
		display: flex;
		cursor: pointer;
	}

	.pagination-numbers-container {
		display: flex;
		padding: 0 10px;

		.pagination-number:not(:last-child) {
			margin-right: 15px;
		}
	}

	@media (min-width: 992px) {
		.arrow-right {
			position: absolute;
			top: 50%;
			right: 2vw;
		}

		.arrow-left {
			position: absolute;
			top: 50%;
			left: 2vw;
		}
	}

	.pagination-number {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 30px;
		height: 30px;
		background: #ea1c2d;
		border-radius: 100%;
		color: white;
		cursor: pointer;
		border: 2px solid #ea1c2d;

		&.active {
			background: white;
			span {
				color: black;
			}
		}

		span {
			font-weight: 600;
			font-size: 11px;
			color: #ffffff;
		}
	}
}
