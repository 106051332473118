.modal-dialog {
	.modal-body {
		white-space: pre-line;
		text-align: justify;
	}
}
.tooltip {
	position: absolute;
	margin-bottom: 50px !important;
}

#profile-view-user {
	margin-top: 400px;
	justify-content: center;

	.profile-desc {
		margin: 15px;
		display: flex;
		flex-direction: column;

		.city {
			font-size: 14px;
			color: #eb0029;
		}

		.name {
			font-weight: 900;
			font-size: 18px;
			justify-content: flex-start;
			margin-bottom: 10px;
			@media (max-width: 425px) {
				justify-content: center;
			}
		}

		.info-container {
			height: 100%;
			display: flex;
			flex-direction: column;
			justify-content: space-evenly;
			margin-top: 5px;
		}

		.inline-redknot-verified {
			display: flex;
			align-items: center;
			font-size: 11px;
			@media (min-width: 993px) {
				display: none;
			}
		}

		.redknot-logo-verified {
			display: block;
			margin-right: 5px;
			width: 10px;
			height: 10px;
			background-size: cover;
			background-position: top;
		}
		@media (max-width: 425px) {
			width: 100%;
		}
		@media (min-width: 993px) {
			max-width: calc(100% - 130px);
			.name {
				margin-left: 10px;
			}
		}
	}
	@media (max-width: 500px) {
		margin-top: 380px;
	}

	@media (max-width: 960px) {
		margin-bottom: 265px;
	}
}

.profile-desc {
	@media (max-width: 992px) {
		.info-container {
			border-left: solid 0.5px lightgray;
		}
	}
	.info-ambassador-box {
		display: flex;
		color: #0f0606;

		margin-left: 10px;
		// margin-top: 15px;
	}
	.ambassador-span {
		font-size: 13px;
		font-weight: normal;
		display: flex;
		.ambassador-span-icon {
			font-weight: normal;
			display: flex;
			margin-right: 6px;
		}
		span {
			width: 15px;
			display: flex;
			justify-content: center;
			align-items: center;
			@media (max-width: 400px) {
				text-align: initial;
				display: flow-root;
			}
		}

		span:last-child {
			width: auto;
			margin-left: 5px;
			align-items: initial;
		}

		@media (max-width: 992px) {
			font-size: 11px;
		}
	}
	.info-ambassador-box {
		display: flex;
		color: #929292;
		line-height: 23px;
		margin-left: 10px;
		@media (max-width: 500px) {
			line-height: 20px;
		}
	}
	.ambassador-span-progress {
		font-size: 11px;
		font-weight: normal;
		margin-left: 5px;
	}
}

.main-interest {
	margin: 15px 0;
	padding: 0 20px;
	display: flex;
	align-items: flex-start;
	flex-direction: column;
	@media (max-width: 500px) {
		width: 100%;
		align-items: center;
	}
	.container-left {
		@media (min-width: 422px) {
			margin-right: 13px;
		}
	}
	.main-title {
		font-weight: 900;
		font-size: 18px;
	}
	.progress {
		background-color: black;
		width: 100px;
		border-radius: 10px;
		margin-right: 7px;
		height: 12px;
		margin-left: 7px;
	}
	.info-ambassador-box {
		display: flex;
		margin-top: 10px;
		align-items: center;
		svg {
			width: 25px;
		}
	}
	.ambassador-span {
		font-size: 11px;
		font-weight: normal;
		margin-right: 12px;

		svg {
			width: 15px;
		}
	}
	.ambassador-span-progress {
		font-size: 11px;
		font-weight: normal;
		color: #929292;
	}
}

.container-interest {
	display: flex;
	justify-content: space-evenly;
	flex-wrap: wrap;
	.container-left {
		display: grid;
		justify-items: center;
	}
	.container-right {
		display: grid;
		justify-items: center;
	}
}
.car-container {
	min-height: 65px;
	background-color: #f4f4f4;
	border-radius: 5px;
	display: flex;
	align-items: center;
	justify-content: space-evenly;
	flex-wrap: wrap;
	padding: 20px;
	@media (max-width: 1200px) {
		width: 50%;
	}
	@media (max-width: 991px) {
		margin-top: 20px;
	}
	@media (max-width: 900px) {
		width: 100%;
	}
	@media (max-width: 400px) {
		height: 85px;
		justify-content: center;
	}
	.car-title-container {
		.car-container-title {
			font-weight: 600;
			font-size: 13px;
			line-height: 16px;
		}
	}
	.car-container-footer {
		font-size: 12px;
		display: inline-grid;
	}
	.car-image-container {
		padding: 0 10px;
	}
	.car-footer {
		display: flex;
		flex-direction: column;
	}
}
.car-verified-container {
	margin-top: 20px;
	align-items: center;
	@media (max-width: 1200px) {
		width: 100%;
		justify-content: center;
		display: flex;
	}
	@media (max-width: 500px) {
		margin: 0 !important;
	}
}
.interview-text {
	margin-top: 10px;
	display: flex;
	font-size: 11px;
	justify-content: center;
	@media (max-width: 1200px) {
		display: none;
	}
	.car-image-container {
		padding: 0 10px;
	}
	a {
		text-decoration: none;
		color: black;
		&:hover {
			text-decoration: none;
			color: black;
		}
		span {
			&:hover {
				color: #dc3545;
			}
		}
	}
}
.car-verified-container {
	margin-top: 35px;
	margin-bottom: 15px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	@media (max-width: 1200px) {
		width: 100%;
		justify-content: center;
		display: flex;
	}
}
.interview-text {
	margin-top: 10px;
	display: flex;
	font-size: 11px;
	justify-content: center;
	@media (max-width: 1200px) {
		display: none;
	}
}

.ambassador-info-container {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	.ambassador-personal-container {
		display: flex;
		align-items: center;
		@media (max-width: 425px) {
			flex-direction: column;
		}
	}
	.profile-picture-ambassador {
		width: 154px;
		height: 154px;
		border-radius: 100%;
		background-size: cover;
		background-position: top;
		position: relative;
		@media (max-width: 992px) {
			width: 100px;
			height: 100px;
		}
		.redknot-logo-verified {
			position: absolute;
			bottom: 15px;
			right: 0;
			width: 30px;
			height: 30px;
			background-size: cover;
			background-position: top;
			@media (max-width: 992px) {
				display: none;
			}
		}
	}
}

.ambassador-personal-info {
	display: flex;
	justify-content: space-around;
	margin-top: 50px;
	border-top: 1px solid #dddddd;
	@media (max-width: 1200px) {
		display: flex;
		flex-wrap: wrap;
	}
	.about-me-container {
		display: flex;
		flex-direction: column;
		margin-top: 60px;
		width: 305px;
		box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.07);
		border-radius: 10px;
		@media (max-width: 1200px) {
			width: 400px;
		}
		@media (max-width: 500px) {
			width: 300px;
		}
		.about-me-img-container {
			display: flex;
			align-items: center;
			justify-content: center;
			margin-top: -45px;
		}
		img {
			width: 75px;
			height: 75px;
			background-color: white;
			border-radius: 100%;
			box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.08);
			padding: 10px;
		}
		.about-me-text-container {
			.about-me-title {
				color: black;
				font-weight: bold;
				font-size: 18px;
				text-align: center;
			}
			text-align: justify;
			font-size: 12px;
			align-self: center;
			color: #737373;
			padding: 22px 42px;
		}
	}
}

#perfect-day-container {
	display: flex;
	flex-wrap: wrap;
	margin-top: 50px;
	border-top: 1px solid #dddddd;
	#timeline-container {
		max-width: 1400px;
		margin: 30px auto;
		box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.07);
		border-radius: 10px;
		padding: 30px;

		.timeline {
			list-style: none;
			padding: 0;
			position: relative;
			display: flex;
			flex-direction: column;

			@media (min-width: 1200px) {
				flex-direction: row;
			}

			&:before {
				content: '';
				margin-left: 100px;
				width: 2px;
				height: 100%;
				background-image: linear-gradient(
					to bottom,
					black 8%,
					black 8%,
					black 100%
				);
				transform: translateX(calc(50% + 30px / 2));

				position: absolute;
				top: 0;
				left: 0;
				@media (max-width: 1200px) {
					margin-top: 35px;
					margin-left: 0px;
					height: 81%;
				}
				@media (max-width: 500px) {
					height: 79%;
				}
				@media (min-width: 1200px) {
					width: 80%;
					height: 2px;
					border-radius: 4px;
					background-image: linear-gradient(
						to right,
						black 8%,
						black 8%,
						black 100%
					);
					transform: translateY(calc(50% + 30px / 2));
				}
			}

			&__step {
				display: flex;
				align-items: center;
				flex: 1;
				text-align: center;
				position: relative;

				flex-direction: row;
				padding: 30px 0;
				margin-top: 5px;

				@media (min-width: 1200px) {
					flex-direction: column;
					padding: 0 30px;
				}
				@media (max-width: 1200px) {
					align-items: baseline;
				}
				@media (max-width: 500px) {
					padding: 10px 0;
				}

				&:before {
					content: '';
					min-width: 30px;
					height: 30px;
					border-radius: 40px;
					border: solid 6px #dc3545;
					background-color: #dc3545;

					margin: 0 30px 0 0;
					@media (min-width: 1200px) {
						margin: 0 0 30px 0;
					}
					@media (max-width: 500px) {
						min-width: 20px;
						height: 20px;
						margin-left: 7px;
					}
				}
			}

			&__title {
				display: inline-block;
				font-size: 12px;
				line-height: 15px;
				text-align: center;
				color: #737373;
				line-height: 15px;
				width: fit-content;
				@media (max-width: 1199px) {
					text-align: left;
				}
				@media (max-width: 500px) {
					text-align: initial;
				}
			}
		}
		.timeline-hour {
			font-size: 12px;
			line-height: 15px;
			text-align: center;
			color: #ea1c2d;
			font-weight: bold;
			@media (max-width: 1200px) {
				text-align: initial;
			}
		}
	}

	.perfect-day-title {
		text-align: center;
		font-style: normal;
		font-weight: bold;
		font-size: 24px;
		line-height: 29px;
		margin-top: 50px;
		color: #ea1c2d;
		width: 100%;

		div {
			margin-top: 10px;
			color: #929292;
		}

		@media (max-width: 500px) {
			font-size: 18px;

			div {
				font-size: 14px;
			}
		}
	}
}

#footer-container {
	margin-top: 50px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 15px 80px;
	width: 100%;
	left: 0px;
	position: fixed;
	bottom: 0px;
	background-color: rgb(255, 255, 255);
	box-shadow: 1px 0px 11px lightgrey;
	flex-wrap: wrap;

	@media (max-width: 960px) {
		height: 260px;
		justify-content: center;
		align-items: center;
		padding: 15px 25px;
	}

	> div:not(.alerts-container) {
		width: 33.3%;
		@media (max-width: 960px) {
			width: 100%;
		}
	}

	.alerts-container {
		width: 100%;

		.alert {
			margin: 0;
			margin-top: 5px;
		}
	}

	.tariff {
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		text-align: left;

		@media (max-width: 960px) {
			text-align: center;
		}
		.price-per-person {
			width: 100%;
			text-align: inherit;
			margin-bottom: 5px;
		}
	}

	.datepicker-container {
		display: flex;
		flex-direction: column;
		align-items: flex-end;

		> span {
			width: 250px;
			color: #dc3545;
			font-weight: bold;
			margin-top: 5px;
			cursor: pointer;
		}

		@media (max-width: 960px) {
			justify-content: inherit;
			align-items: center;
		}
	}

	.amount {
		font-weight: bold;
		font-size: 20px;
		line-height: 16px;
		color: #ea1c2d;
		text-align: inherit;
	}

	.basis {
		font-size: 11px;
	}

	.button-primary {
		min-width: 250px;
		margin-top: 5px;
	}

	.alert-text {
		max-width: 365px;
		font-size: 10px;
	}

	.num-travelers-container {
		margin-bottom: 5px;
		@media (max-width: 426px) {
			> div {
				margin-right: 0;
			}
		}
	}

	@media (max-width: 600px) {
		justify-content: center;
		.button-primary {
			width: 100%;
		}
	}
}

.readBtn {
	background: none !important;
	border: none;
	padding: 0 !important;
	font-family: arial, sans-serif;
	color: #ea1c2d;
	text-decoration: underline;
	cursor: pointer;
	// margin: 10px;
	margin-left: 0;
}

#share-container {
	margin-top: 20px;
	color: #ea1c2d;
	margin-bottom: 210px;

	button:not(:last-child) {
		margin-right: 10px;
	}
}
