@import url(https://fonts.googleapis.com/css?family=Montserrat:400,500,600,700,800|Open+Sans&display=swap);
.goog-te-banner-frame.skiptranslate {
  display: none !important; }

#google_translate_element {
  position: absolute;
  top: calc(-4px + 16px);
  right: 16px !important; }
  @media screen and (max-width: 450px) {
    #google_translate_element {
      position: inherit; } }

.goog-te-combo,
.goog-te-banner *,
.goog-te-ftab *,
.goog-te-menu *,
.goog-te-menu2 *,
.goog-te-balloon * {
  font-family: arial;
  font-size: 10pt;
  background-repeat: no-repeat;
  text-indent: 20px;
  background-color: #fff;
  color: #000 !important; }

.goog-logo-link {
  display: none !important; }

.goog-te-gadget {
  color: transparent !important; }

.goog-te-gadget .goog-te-combo {
  margin: 2px 0 !important; }

/* Spinner */
#spinner-container {
  height: calc(100vh - 140px);
  display: flex;
  align-items: center;
  justify-content: center; }
  #spinner-container .spinner-border {
    width: 5rem;
    height: 5rem;
    color: #eb0029;
    border: 0.4em solid currentColor;
    border-right-color: transparent; }

.alert {
  font-size: 12px;
  margin-top: 1rem; }

.auth-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 0 30px;
  margin-top: 33px; }

.brand-header {
  background-image: url(/static/media/brand_header_background.d6157537.png);
  background-size: cover;
  height: 135px;
  display: flex;
  justify-content: center; }

.brand-header img {
  margin-top: 37px;
  height: 40px; }

.auth-divider {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 18px 0; }

.auth-divider-line {
  width: 42%; }

.auth-divider-o {
  font-family: 'Open Sans';
  font-weight: normal; }

.button-auth {
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  font-size: 12px;
  font-weight: bold;
  border-radius: 10px;
  width: 100%;
  cursor: pointer;
  background: -webkit-linear-gradient(98.52deg, #ffffff 0%, #eeeeee 100%);
  background: linear-gradient(-8.52deg, #ffffff 0%, #eeeeee 100%);
  box-shadow: -1px 1px 6px rgba(0, 0, 0, 0.07);
  border-radius: 5px; }

.button-auth img {
  margin-right: 10px;
  height: 18px; }

.auth-form {
  margin-bottom: 30px; }

.auth-fields {
  margin: 4px 0; }

.link-primary {
  color: #eb0029;
  font-weight: 700;
  cursor: pointer; }

.page-title {
  font-size: 20px;
  font-weight: bold; }

#nav-brand {
  width: 185px;
  cursor: pointer; }

#navbar button.navbar-toggler {
  padding: 0;
  margin: 0 !important; }
  #navbar button.navbar-toggler span {
    font-size: 20px; }

#navbar .agent {
  background-color: darkslateblue; }

#navbar nav {
  position: fixed;
  top: 0;
  width: 100%;
  box-shadow: 1px 0px 11px lightgray;
  background-color: white;
  z-index: 3; }

#navbar i {
  cursor: pointer; }

.nav .navbar-toggler-icon {
  /* background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(0, 0, 0, 0)''"); */ }

.navbar-collapse {
  background-color: black;
  margin: 0.5rem -1rem -0.5rem -1rem; }

nav .navbar-nav .nav-item a.nav-link {
  color: white; }

nav .navbar-nav .nav-item a.nav-link:focus {
  color: #eb0029;
  font-weight: bold; }

.nav-link {
  margin: 18px 0;
  cursor: pointer; }

nav .navbar-nav .nav-item a.nav-link:hover {
  color: #eb0029;
  font-weight: bold; }

.nav-footer {
  height: 73px;
  display: flex;
  padding: 17px;
  justify-content: space-evenly;
  align-items: center;
  background-color: #1b1b1b; }

.nav-footer .nav-item {
  width: 50%; }

.nav-footer .nav-item:nth-child(2) {
  width: 50%;
  border-left: solid 1px #2c2c2c; }

nav .navbar-nav .nav-footer a.nav-link {
  color: #eb0029;
  font-weight: bold; }

/* Navigation end */

.car-type-box.active {
  background-color: gray; }

#profile-view {
  margin-top: 35px; }
  #profile-view .profile-desc {
    margin-top: 15px;
    display: flex;
    flex-direction: column; }
    #profile-view .profile-desc .city {
      font-size: 14px;
      color: #eb0029; }
    #profile-view .profile-desc .country {
      font-size: 12px;
      color: #a0a0a0; }
    #profile-view .profile-desc .name {
      font-weight: 900;
      font-size: 22px;
      margin-top: 10px; }
    #profile-view .profile-desc .bio {
      font-size: 16px;
      margin-top: 16px;
      color: #a0a0a0;
      text-align: justify;
      -moz-text-align-last: center;
           text-align-last: center;
      white-space: pre-line; }
    #profile-view .profile-desc .user-profile-field {
      text-align: left; }
      #profile-view .profile-desc .user-profile-field label {
        align-self: flex-start;
        font-size: 12px;
        font-weight: 600; }
      #profile-view .profile-desc .user-profile-field p {
        font-size: 16px;
        color: #a0a0a0;
        text-align: left;
        white-space: pre-line; }
  #profile-view #lifestyle-photos {
    text-align: left; }
    #profile-view #lifestyle-photos label {
      font-size: 12px;
      font-weight: 600; }
  #profile-view .lifestyle-grid {
    display: flex;
    flex-wrap: wrap; }
    #profile-view .lifestyle-grid .lifestyle-pic {
      width: calc(33% - 10px);
      height: 30vw;
      margin: 0 5px 10px 5px;
      background-size: cover;
      background-position: center; }
      #profile-view .lifestyle-grid .lifestyle-pic:last-child {
        margin-right: 0; }
  #profile-view #profile-footer {
    position: -webkit-sticky;
    position: sticky;
    bottom: -2px;
    background-color: white;
    padding-bottom: 15px; }
  #profile-view .tariff {
    text-align: right;
    padding: 25px 0; }
    #profile-view .tariff .amount {
      font-weight: 900;
      font-size: 18px; }
    #profile-view .tariff .basis {
      font-weight: 500;
      font-size: 10px; }

#profile-edit {
  margin-top: 35px; }
  #profile-edit form {
    flex-direction: column; }
  #profile-edit .edit-fields {
    margin-top: 30px; }
  #profile-edit .profile-photo {
    position: relative; }
    #profile-edit .profile-photo .upload-photo-icon {
      position: absolute;
      right: 0;
      bottom: 0;
      height: 43px;
      width: 43px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #eb0029;
      border-radius: 100%;
      color: white; }
  #profile-edit .example-day-entry {
    display: flex;
    margin-bottom: 10px; }
    #profile-edit .example-day-entry input[type='time'] {
      margin-right: 10px;
      max-width: 100px;
      cursor: text; }
  #profile-edit .lifestyle-container ul {
    padding: 0;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%; }
  #profile-edit .lifestyle-container .sortable-lifestyle-pic {
    width: 32%;
    height: 300px;
    margin: 5px; }
    @media (max-width: 767px) {
      #profile-edit .lifestyle-container .sortable-lifestyle-pic {
        width: calc(50% - 10px);
        height: calc(50vw - 10px); } }

#step-1 .country {
  width: 150px;
  height: 100px;
  background-color: white;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer; }
  #step-1 .country:hover {
    box-shadow: 0px 0px 7px #ea1c2d; }
  @media (max-width: 500px) {
    #step-1 .country {
      width: 100px;
      height: 100px;
      background-size: contain;
      background-repeat: round;
      font-size: 14px; } }
  #step-1 .country p {
    margin: auto;
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    text-align: center;
    color: white; }
    @media (max-width: 500px) {
      #step-1 .country p {
        font-size: 14px; } }

#step-1 .country-box {
  width: 150px;
  height: 100px;
  margin-bottom: 10px;
  background-color: white;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer; }
  #step-1 .country-box.active {
    border: 2px solid #ea1c2d; }
  #step-1 .country-box.inactive {
    border: none !important;
    -webkit-filter: grayscale(100%);
    /* Safari 6.0 - 9.0 */
    filter: grayscale(100%); }
  #step-1 .country-box p {
    margin: auto;
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    text-align: center;
    color: white; }
    @media (max-width: 500px) {
      #step-1 .country-box p {
        font-size: 14px; } }
  @media (max-width: 500px) {
    #step-1 .country-box {
      width: 100px;
      height: 100px;
      background-size: cover;
      background-repeat: no-repeat;
      font-size: 14px; } }
  @media (max-width: 350px) {
    #step-1 .country-box {
      width: 100%; } }

#step-2 .city-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 60%;
  height: calc(300px - 80px);
  padding-left: 50px; }
  @media (max-width: 900px) {
    #step-2 .city-container {
      width: 60%;
      padding-left: 0; } }
  @media (max-width: 600px) {
    #step-2 .city-container {
      width: 100%; } }

#step-2 .city-box {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.2);
  border-radius: 15px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  cursor: pointer; }
  #step-2 .city-box:hover {
    box-shadow: 0px 0px 7px #ea1c2d; }
  #step-2 .city-box.active {
    border: 2px solid #ea1c2d; }
  #step-2 .city-box.inactive {
    -webkit-filter: grayscale(100%);
    /* Safari 6.0 - 9.0 */
    filter: grayscale(100%); }
  #step-2 .city-box p {
    margin: auto;
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    text-align: center;
    color: white; }

#new-trip #step-3.main-container {
  min-height: 460px; }

#new-trip #step-3 .number-people-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  width: 60%;
  height: calc(453px - 80px);
  padding-left: 50px; }
  @media (max-width: 900px) {
    #new-trip #step-3 .number-people-container {
      width: 60%;
      padding-left: 0; } }
  @media (max-width: 600px) {
    #new-trip #step-3 .number-people-container {
      width: 100%; } }
  #new-trip #step-3 .number-people-container .number-people-box {
    margin-top: 5px;
    display: flex;
    width: 100%;
    height: 62px;
    border-radius: 10px;
    background-color: black;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #ffffff;
    padding: 10px;
    background-size: cover;
    background-repeat: no-repeat;
    z-index: 1;
    cursor: pointer; }
    #new-trip #step-3 .number-people-container .number-people-box span {
      text-transform: capitalize; }
    #new-trip #step-3 .number-people-container .number-people-box:hover {
      box-shadow: 0px 0px 7px #ea1c2d; }
    #new-trip #step-3 .number-people-container .number-people-box.active {
      border: 2px solid #ea1c2d; }
    #new-trip #step-3 .number-people-container .number-people-box.inactive {
      -webkit-filter: grayscale(100%);
      /* Safari 6.0 - 9.0 */
      filter: grayscale(100%); }

#new-trip #step-3 .description {
  display: none;
  margin-top: -15px;
  padding: 20px 15px 15px 15px;
  background: #eeeeee;
  font-size: 10px;
  font-style: italic;
  line-height: 12px;
  text-align: left;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px; }
  #new-trip #step-3 .description.active {
    display: block; }

#new-trip #step-3 .inputs-container {
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin: 10px 0;
  font-style: normal;
  font-size: 24px; }
  #new-trip #step-3 .inputs-container .number-input {
    display: flex;
    align-items: center;
    /* Firefox */ }
    #new-trip #step-3 .inputs-container .number-input span {
      display: flex;
      align-items: center;
      text-align: center;
      color: #ffffff;
      width: 25px;
      height: 25px;
      background-color: #ea1c2d;
      display: inline-grid;
      cursor: pointer; }
    #new-trip #step-3 .inputs-container .number-input .btn-increase {
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px; }
    #new-trip #step-3 .inputs-container .number-input .btn-decrease {
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px; }
    #new-trip #step-3 .inputs-container .number-input .btn-increase,
    #new-trip #step-3 .inputs-container .number-input .btn-decrease {
      -webkit-touch-callout: none;
      /* iOS Safari */
      -webkit-user-select: none;
      /* Safari */
      /* Konqueror HTML */
      -moz-user-select: none;
      /* Old versions of Firefox */
      -ms-user-select: none;
      /* Internet Explorer/Edge */
      user-select: none;
      /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */ }
    #new-trip #step-3 .inputs-container .number-input .num-travelers {
      width: 80px;
      height: 25px;
      font-family: Montserrat;
      font-size: 12px;
      text-align: center;
      color: #929292;
      border: none; }
    #new-trip #step-3 .inputs-container .number-input input:disabled {
      background: white; }
    #new-trip #step-3 .inputs-container .number-input input::-webkit-outer-spin-button,
    #new-trip #step-3 .inputs-container .number-input input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0; }
    #new-trip #step-3 .inputs-container .number-input input[type='number'] {
      -moz-appearance: textfield; }

#step-4 .dates-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; }
  @media (max-width: 800px) {
    #step-4 .dates-container {
      margin: auto;
      height: 100%;
      padding: 11px;
      flex-direction: column; } }
  #step-4 .dates-container .no-dates-checkbox .btn-travel {
    border: none;
    padding: 20px;
    border-radius: 20px;
    color: #ffffff;
    background-color: #ea1c2d;
    width: 265.02px;
    text-align: justify; }
    @media (max-width: 500px) {
      #step-4 .dates-container .no-dates-checkbox .btn-travel {
        margin: auto; } }
    #step-4 .dates-container .no-dates-checkbox .btn-travel.inactive {
      background-color: #e5e5e5;
      color: #929292; }

#step-4 .options-container input {
  padding: 15px;
  width: 265px;
  height: 50px;
  background-color: #e5e5e5;
  color: #929292;
  border: none;
  border-radius: 10px;
  font-weight: 600; }
  #step-4 .options-container input.filled {
    background-color: #dc3545;
    color: white; }

@media (max-width: 800px) {
  #step-4 .options-container {
    width: 100%; } }

/* Interests */
button.interest-box {
  color: black; }

button.interest-box.selected {
  color: red; }

#interests-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; }
  @media screen and (max-width: 995px) {
    #interests-grid .interest-box {
      width: calc(50% - 14px); } }
  @media screen and (min-width: 600px) {
    #interests-grid .interest-box {
      width: calc(32% - 9px); } }
  @media screen and (min-width: 1000px) {
    #interests-grid .interest-box {
      width: 159px; } }
  #interests-grid button {
    color: #eb0029;
    border: none;
    background-color: white;
    box-shadow: -1px 1px 8px rgba(0, 0, 0, 0.09);
    font-size: 12px;
    margin: 7px;
    padding: 20px;
    width: calc(24% - 60px);
    border-radius: 5px; }
    #interests-grid button.selected {
      background-color: #eb0029;
      color: white; }
  #interests-grid .interest-title {
    display: flex;
    justify-content: center;
    margin-top: 5%;
    align-items: center; }
    #interests-grid .interest-title .step-container {
      margin-right: 2%; }
      #interests-grid .interest-title .step-container .step {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
        background: white;
        border-radius: 50%;
        border-style: solid;
        border-color: #ea1c2d; }
      #interests-grid .interest-title .step-container span {
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 29px;
        text-align: center;
        color: #ea1c2d; }
    #interests-grid .interest-title .title-container {
      width: 60%;
      text-align: left;
      text-align: initial; }
      #interests-grid .interest-title .title-container h2 {
        font-weight: bold;
        font-size: 24px;
        line-height: 29px; }

#new-trip #step-5 #spinner-container {
  height: 100%; }

#new-trip #step-5.main-container {
  flex-direction: column;
  min-width: 888px; }
  @media (max-width: 1000px) {
    #new-trip #step-5.main-container {
      height: auto;
      min-width: unset; } }
  #new-trip #step-5.main-container .options-container {
    justify-content: center; }
  #new-trip #step-5.main-container .main-title-container {
    width: 100%;
    align-items: center;
    border: none; }
  #new-trip #step-5.main-container .options-container {
    width: 100%;
    padding: 0; }
    #new-trip #step-5.main-container .options-container .user-input {
      width: 100%; }

#new-trip form {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center; }

#new-trip .main-container {
  display: flex;
  justify-content: space-between;
  width: 750px;
  min-height: 300px;
  background: #ffffff;
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.2);
  border-radius: 15px;
  padding: 40px 60px; }
  @media (max-width: 900px) {
    #new-trip .main-container {
      justify-content: center;
      flex-wrap: wrap;
      width: 750px; } }
  @media (max-width: 600px) {
    #new-trip .main-container {
      padding: 30px 45px; } }
  @media (max-width: 350px) {
    #new-trip .main-container {
      padding: 20px; } }
  #new-trip .main-container .main-title-container {
    display: flex;
    flex-direction: column;
    width: 40%;
    justify-content: center;
    align-items: flex-start;
    border-right: solid; }
    @media (max-width: 900px) {
      #new-trip .main-container .main-title-container {
        width: 100%;
        flex-direction: inherit;
        border-right: none;
        align-items: center;
        margin-bottom: 15px; } }
    @media (max-width: 400px) {
      #new-trip .main-container .main-title-container {
        margin-top: 10px; } }
    #new-trip .main-container .main-title-container .title-container {
      text-align: left;
      text-align: initial;
      padding-right: 20px; }
      @media (max-width: 900px) {
        #new-trip .main-container .main-title-container .title-container {
          width: auto;
          padding: 0; } }
      #new-trip .main-container .main-title-container .title-container h2 {
        font-weight: bold;
        font-size: 24px;
        line-height: 29px; }
        @media (max-width: 900px) {
          #new-trip .main-container .main-title-container .title-container h2 {
            font-size: 18px;
            margin: auto;
            line-height: 22px; } }
    #new-trip .main-container .main-title-container .step-container {
      margin-bottom: 10px;
      margin-right: 15px; }
      @media (max-width: 900px) {
        #new-trip .main-container .main-title-container .step-container {
          margin-bottom: 0; } }
      #new-trip .main-container .main-title-container .step-container .step {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
        background: white;
        border-radius: 50%;
        border-style: solid;
        border-color: #ea1c2d; }
      #new-trip .main-container .main-title-container .step-container span {
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 29px;
        text-align: center;
        color: #ea1c2d; }
  #new-trip .main-container .options-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 60%;
    padding-left: 50px;
    align-items: center; }
    @media (max-width: 800px) {
      #new-trip .main-container .options-container {
        width: 100%;
        justify-content: space-evenly;
        padding-left: 0; } }
  #new-trip .main-container .btn-travel {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    border: none;
    width: 234px;
    border-radius: 20px;
    color: #ffffff;
    background-color: #ea1c2d; }

#new-trip .progress-bar-container {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  width: 100%;
  border-radius: 50px; }
  #new-trip .progress-bar-container .progress {
    background-color: #000;
    width: 340px;
    border-radius: 50px;
    height: 35px; }
    #new-trip .progress-bar-container .progress .progress-bar {
      border-radius: 50px; }
  #new-trip .progress-bar-container .bg-danger {
    background-color: #dc3545 !important; }

#new-trip .progress-status {
  position: absolute;
  color: white;
  display: flex;
  width: 340px;
  justify-content: flex-end;
  margin-left: -17px;
  margin-top: 7px; }
  @media (max-width: 399px) {
    #new-trip .progress-status {
      margin-left: -30px; } }

.btn-container {
  width: 100%; }
  .btn-container .btn-primary.inactive:first-child {
    margin-right: 20px; }

#new-path-container {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  max-width: none; }

.pagination {
  margin-top: 30px;
  width: 100%;
  justify-content: center;
  align-items: center; }
  .pagination > span {
    color: #ea1c2d;
    font-size: 26px;
    display: flex;
    cursor: pointer; }
  .pagination .pagination-numbers-container {
    display: flex;
    padding: 0 10px; }
    .pagination .pagination-numbers-container .pagination-number:not(:last-child) {
      margin-right: 15px; }
  @media (min-width: 992px) {
    .pagination .arrow-right {
      position: absolute;
      top: 50%;
      right: 2vw; }
    .pagination .arrow-left {
      position: absolute;
      top: 50%;
      left: 2vw; } }
  .pagination .pagination-number {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    background: #ea1c2d;
    border-radius: 100%;
    color: white;
    cursor: pointer;
    border: 2px solid #ea1c2d; }
    .pagination .pagination-number.active {
      background: white; }
      .pagination .pagination-number.active span {
        color: black; }
    .pagination .pagination-number span {
      font-weight: 600;
      font-size: 11px;
      color: #ffffff; }

.ambassador-result-card {
  position: relative;
  margin-top: 60px;
  padding: 20px;
  width: 100%;
  height: calc(95vh - 80px);
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.07);
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column; }
  .ambassador-result-card button {
    font-size: 9px;
    min-width: 100px;
    line-height: 11px; }
  .ambassador-result-card .card-info-body {
    width: 100%;
    height: calc(50% - 90px);
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap; }
  @media screen and (min-width: 550px) {
    .ambassador-result-card {
      width: 275px; } }

.card-photo-container {
  position: absolute;
  top: 0;
  width: 100%;
  height: 48%;
  cursor: pointer; }
  .card-photo-container .photo-profile {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    background-color: white; }

#top-score-badge {
  display: flex;
  align-items: center;
  position: absolute;
  top: -30px;
  left: 0;
  color: #ffe600;
  background-color: #000;
  padding: 5px 15px;
  padding-bottom: 50px;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  z-index: -1; }
  #top-score-badge svg {
    font-size: 20px;
    margin-right: 5px; }
  #top-score-badge div {
    font-size: 9px;
    font-weight: bold; }

.card-info {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  font-size: 10px; }
  .card-info .card-headline {
    width: 100%;
    display: flex;
    justify-content: space-between; }
    .card-info .card-headline > span {
      font-size: 18px;
      font-weight: bold; }
    .card-info .card-headline .redknot-verified-badge {
      display: flex;
      align-items: center; }
      .card-info .card-headline .redknot-verified-badge span {
        font-size: 11px;
        font-weight: normal;
        margin-left: 5px;
        line-height: 20px; }
  .card-info .card-data {
    display: flex;
    justify-content: space-between;
    width: 100%;
    border-left: 0.5px solid #dddddd;
    border-right: 0.5px solid #dddddd;
    margin-bottom: 10px; }
    .card-info .card-data .info-box {
      width: 50%;
      display: flex;
      align-items: center;
      padding-left: 6px;
      color: #929292;
      line-height: 20px; }
      .card-info .card-data .info-box:not(:last-child) {
        border-right: 0.5px solid #dddddd; }
      .card-info .card-data .info-box span {
        font-size: 8.5px;
        font-weight: normal;
        display: flex;
        align-items: center;
        line-height: 11px; }
        .card-info .card-data .info-box span img {
          margin-right: 5px; }

.interests-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  width: 100%;
  border: 1px solid #ea1c2d;
  border-radius: 10px;
  border-top-left-radius: 0;
  padding: 7px 10px;
  margin-bottom: 10px;
  height: auto;
  max-height: 70px;
  align-self: flex-start; }
  .interests-container > span {
    font-weight: 600;
    font-size: 10px;
    color: #ea1c2d; }
  .interests-container p {
    font-size: 9px;
    line-height: 11px;
    margin: 0; }

.card-info-footer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  border-top: 0.5px solid #dddddd;
  padding-top: 15px; }
  .card-info-footer button {
    width: 100px; }
  .card-info-footer .pricing {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    font-size: 9px;
    margin-bottom: 10px; }
    .card-info-footer .pricing .price-total {
      font-size: 14px;
      font-weight: bold;
      text-align: left;
      color: #737373;
      line-height: normal;
      line-height: initial; }
    .card-info-footer .pricing .price-detail {
      font-weight: normal;
      font-size: 9px;
      color: black;
      text-align: left; }

#result-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around; }
  #result-grid .result-text {
    font-weight: 600;
    font-size: 18px; }
  #result-grid h4 {
    width: 100%;
    font-size: 14px; }

.custom-modal .modal-body {
  padding: 0px; }

.custom-modal .modal-content {
  border: none;
  border-radius: 0.3rem;
  outline: 0;
  background-color: transparent; }

.custom-modal .close {
  z-index: 1;
  margin-top: 10px !important;
  position: absolute;
  right: 20px;
  font-size: 4.5rem;
  padding: 0px !important; }

#gallery-modal {
  display: flex !important;
  align-items: center;
  justify-content: center;
  height: 100%; }
  #gallery-modal .modal-dialog {
    text-align: center;
    width: 100%; }
  #gallery-modal .modal-content {
    background-color: transparent;
    border: none; }
  #gallery-modal .selected-photo {
    min-height: 500px;
    height: 500px;
    width: 100%;
    margin-bottom: 20px;
    background-position: center;
    background-size: cover; }
  #gallery-modal .gallery-photo {
    min-width: 100px;
    width: 100px;
    height: 100px;
    background-position: center;
    background-size: cover;
    margin-right: 10px; }
  #gallery-modal .thumbnail-container {
    width: 100%;
    display: flex;
    overflow-x: scroll; }
  #gallery-modal .control-container {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 20px; }
    #gallery-modal .control-container span {
      font-size: 30px;
      padding: 0 10px;
      color: #eb0029; }
    #gallery-modal .control-container .close {
      position: absolute;
      top: 0;
      left: 0;
      margin-left: 90%;
      cursor: pointer; }

.num-travelers-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center; }
  .num-travelers-container > div:first-child {
    margin-right: 5px; }
  .num-travelers-container .number {
    background-color: white;
    width: 80px;
    height: 25px;
    font-family: Montserrat;
    font-size: 12px;
    text-align: center;
    color: #929292;
    border: none; }
  .num-travelers-container span {
    align-items: center;
    text-align: center;
    color: #fff;
    width: 25px;
    height: 25px;
    background-color: #ea1c2d;
    display: inline-grid;
    cursor: pointer; }
  .num-travelers-container .btn-increase {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px; }
  .num-travelers-container .btn-decrease {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px; }
  .num-travelers-container .btn-increase,
  .num-travelers-container .btn-decrease {
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Old versions of Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */ }

.carousel.carousel-slider {
  width: 100%;
  margin-top: -30px;
  position: absolute;
  height: 400px;
  left: 0; }
  @media (max-width: 451px) {
    .carousel.carousel-slider {
      margin-top: -85px; } }

.carousel-container {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: 400px; }

.modal-dialog .modal-body {
  white-space: pre-line;
  text-align: justify; }

.tooltip {
  position: absolute;
  margin-bottom: 50px !important; }

#profile-view-user {
  margin-top: 400px;
  justify-content: center; }
  #profile-view-user .profile-desc {
    margin: 15px;
    display: flex;
    flex-direction: column; }
    #profile-view-user .profile-desc .city {
      font-size: 14px;
      color: #eb0029; }
    #profile-view-user .profile-desc .name {
      font-weight: 900;
      font-size: 18px;
      justify-content: flex-start;
      margin-bottom: 10px; }
      @media (max-width: 425px) {
        #profile-view-user .profile-desc .name {
          justify-content: center; } }
    #profile-view-user .profile-desc .info-container {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      margin-top: 5px; }
    #profile-view-user .profile-desc .inline-redknot-verified {
      display: flex;
      align-items: center;
      font-size: 11px; }
      @media (min-width: 993px) {
        #profile-view-user .profile-desc .inline-redknot-verified {
          display: none; } }
    #profile-view-user .profile-desc .redknot-logo-verified {
      display: block;
      margin-right: 5px;
      width: 10px;
      height: 10px;
      background-size: cover;
      background-position: top; }
    @media (max-width: 425px) {
      #profile-view-user .profile-desc {
        width: 100%; } }
    @media (min-width: 993px) {
      #profile-view-user .profile-desc {
        max-width: calc(100% - 130px); }
        #profile-view-user .profile-desc .name {
          margin-left: 10px; } }
  @media (max-width: 500px) {
    #profile-view-user {
      margin-top: 380px; } }
  @media (max-width: 960px) {
    #profile-view-user {
      margin-bottom: 265px; } }

@media (max-width: 992px) {
  .profile-desc .info-container {
    border-left: solid 0.5px lightgray; } }

.profile-desc .info-ambassador-box {
  display: flex;
  color: #0f0606;
  margin-left: 10px; }

.profile-desc .ambassador-span {
  font-size: 13px;
  font-weight: normal;
  display: flex; }
  .profile-desc .ambassador-span .ambassador-span-icon {
    font-weight: normal;
    display: flex;
    margin-right: 6px; }
  .profile-desc .ambassador-span span {
    width: 15px;
    display: flex;
    justify-content: center;
    align-items: center; }
    @media (max-width: 400px) {
      .profile-desc .ambassador-span span {
        text-align: left;
        text-align: initial;
        display: flow-root; } }
  .profile-desc .ambassador-span span:last-child {
    width: auto;
    margin-left: 5px;
    align-items: initial; }
  @media (max-width: 992px) {
    .profile-desc .ambassador-span {
      font-size: 11px; } }

.profile-desc .info-ambassador-box {
  display: flex;
  color: #929292;
  line-height: 23px;
  margin-left: 10px; }
  @media (max-width: 500px) {
    .profile-desc .info-ambassador-box {
      line-height: 20px; } }

.profile-desc .ambassador-span-progress {
  font-size: 11px;
  font-weight: normal;
  margin-left: 5px; }

.main-interest {
  margin: 15px 0;
  padding: 0 20px;
  display: flex;
  align-items: flex-start;
  flex-direction: column; }
  @media (max-width: 500px) {
    .main-interest {
      width: 100%;
      align-items: center; } }
  @media (min-width: 422px) {
    .main-interest .container-left {
      margin-right: 13px; } }
  .main-interest .main-title {
    font-weight: 900;
    font-size: 18px; }
  .main-interest .progress {
    background-color: black;
    width: 100px;
    border-radius: 10px;
    margin-right: 7px;
    height: 12px;
    margin-left: 7px; }
  .main-interest .info-ambassador-box {
    display: flex;
    margin-top: 10px;
    align-items: center; }
    .main-interest .info-ambassador-box svg {
      width: 25px; }
  .main-interest .ambassador-span {
    font-size: 11px;
    font-weight: normal;
    margin-right: 12px; }
    .main-interest .ambassador-span svg {
      width: 15px; }
  .main-interest .ambassador-span-progress {
    font-size: 11px;
    font-weight: normal;
    color: #929292; }

.container-interest {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap; }
  .container-interest .container-left {
    display: grid;
    justify-items: center; }
  .container-interest .container-right {
    display: grid;
    justify-items: center; }

.car-container {
  min-height: 65px;
  background-color: #f4f4f4;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
  padding: 20px; }
  @media (max-width: 1200px) {
    .car-container {
      width: 50%; } }
  @media (max-width: 991px) {
    .car-container {
      margin-top: 20px; } }
  @media (max-width: 900px) {
    .car-container {
      width: 100%; } }
  @media (max-width: 400px) {
    .car-container {
      height: 85px;
      justify-content: center; } }
  .car-container .car-title-container .car-container-title {
    font-weight: 600;
    font-size: 13px;
    line-height: 16px; }
  .car-container .car-container-footer {
    font-size: 12px;
    display: inline-grid; }
  .car-container .car-image-container {
    padding: 0 10px; }
  .car-container .car-footer {
    display: flex;
    flex-direction: column; }

.car-verified-container {
  margin-top: 20px;
  align-items: center; }
  @media (max-width: 1200px) {
    .car-verified-container {
      width: 100%;
      justify-content: center;
      display: flex; } }
  @media (max-width: 500px) {
    .car-verified-container {
      margin: 0 !important; } }

.interview-text {
  margin-top: 10px;
  display: flex;
  font-size: 11px;
  justify-content: center; }
  @media (max-width: 1200px) {
    .interview-text {
      display: none; } }
  .interview-text .car-image-container {
    padding: 0 10px; }
  .interview-text a {
    text-decoration: none;
    color: black; }
    .interview-text a:hover {
      text-decoration: none;
      color: black; }
    .interview-text a span:hover {
      color: #dc3545; }

.car-verified-container {
  margin-top: 35px;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between; }
  @media (max-width: 1200px) {
    .car-verified-container {
      width: 100%;
      justify-content: center;
      display: flex; } }

.interview-text {
  margin-top: 10px;
  display: flex;
  font-size: 11px;
  justify-content: center; }
  @media (max-width: 1200px) {
    .interview-text {
      display: none; } }

.ambassador-info-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap; }
  .ambassador-info-container .ambassador-personal-container {
    display: flex;
    align-items: center; }
    @media (max-width: 425px) {
      .ambassador-info-container .ambassador-personal-container {
        flex-direction: column; } }
  .ambassador-info-container .profile-picture-ambassador {
    width: 154px;
    height: 154px;
    border-radius: 100%;
    background-size: cover;
    background-position: top;
    position: relative; }
    @media (max-width: 992px) {
      .ambassador-info-container .profile-picture-ambassador {
        width: 100px;
        height: 100px; } }
    .ambassador-info-container .profile-picture-ambassador .redknot-logo-verified {
      position: absolute;
      bottom: 15px;
      right: 0;
      width: 30px;
      height: 30px;
      background-size: cover;
      background-position: top; }
      @media (max-width: 992px) {
        .ambassador-info-container .profile-picture-ambassador .redknot-logo-verified {
          display: none; } }

.ambassador-personal-info {
  display: flex;
  justify-content: space-around;
  margin-top: 50px;
  border-top: 1px solid #dddddd; }
  @media (max-width: 1200px) {
    .ambassador-personal-info {
      display: flex;
      flex-wrap: wrap; } }
  .ambassador-personal-info .about-me-container {
    display: flex;
    flex-direction: column;
    margin-top: 60px;
    width: 305px;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.07);
    border-radius: 10px; }
    @media (max-width: 1200px) {
      .ambassador-personal-info .about-me-container {
        width: 400px; } }
    @media (max-width: 500px) {
      .ambassador-personal-info .about-me-container {
        width: 300px; } }
    .ambassador-personal-info .about-me-container .about-me-img-container {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: -45px; }
    .ambassador-personal-info .about-me-container img {
      width: 75px;
      height: 75px;
      background-color: white;
      border-radius: 100%;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.08);
      padding: 10px; }
    .ambassador-personal-info .about-me-container .about-me-text-container {
      text-align: justify;
      font-size: 12px;
      align-self: center;
      color: #737373;
      padding: 22px 42px; }
      .ambassador-personal-info .about-me-container .about-me-text-container .about-me-title {
        color: black;
        font-weight: bold;
        font-size: 18px;
        text-align: center; }

#perfect-day-container {
  display: flex;
  flex-wrap: wrap;
  margin-top: 50px;
  border-top: 1px solid #dddddd; }
  #perfect-day-container #timeline-container {
    max-width: 1400px;
    margin: 30px auto;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.07);
    border-radius: 10px;
    padding: 30px; }
    #perfect-day-container #timeline-container .timeline {
      list-style: none;
      padding: 0;
      position: relative;
      display: flex;
      flex-direction: column; }
      @media (min-width: 1200px) {
        #perfect-day-container #timeline-container .timeline {
          flex-direction: row; } }
      #perfect-day-container #timeline-container .timeline:before {
        content: '';
        margin-left: 100px;
        width: 2px;
        height: 100%;
        background-image: -webkit-gradient(linear, left top, left bottom, color-stop(8%, black), color-stop(8%, black), to(black));
        background-image: -webkit-linear-gradient(top, black 8%, black 8%, black 100%);
        background-image: linear-gradient(to bottom, black 8%, black 8%, black 100%);
        -webkit-transform: translateX(calc(50% + 30px / 2));
                transform: translateX(calc(50% + 30px / 2));
        position: absolute;
        top: 0;
        left: 0; }
        @media (max-width: 1200px) {
          #perfect-day-container #timeline-container .timeline:before {
            margin-top: 35px;
            margin-left: 0px;
            height: 81%; } }
        @media (max-width: 500px) {
          #perfect-day-container #timeline-container .timeline:before {
            height: 79%; } }
        @media (min-width: 1200px) {
          #perfect-day-container #timeline-container .timeline:before {
            width: 80%;
            height: 2px;
            border-radius: 4px;
            background-image: -webkit-gradient(linear, left top, right top, color-stop(8%, black), color-stop(8%, black), to(black));
            background-image: -webkit-linear-gradient(left, black 8%, black 8%, black 100%);
            background-image: linear-gradient(to right, black 8%, black 8%, black 100%);
            -webkit-transform: translateY(calc(50% + 30px / 2));
                    transform: translateY(calc(50% + 30px / 2)); } }
      #perfect-day-container #timeline-container .timeline__step {
        display: flex;
        align-items: center;
        flex: 1 1;
        text-align: center;
        position: relative;
        flex-direction: row;
        padding: 30px 0;
        margin-top: 5px; }
        @media (min-width: 1200px) {
          #perfect-day-container #timeline-container .timeline__step {
            flex-direction: column;
            padding: 0 30px; } }
        @media (max-width: 1200px) {
          #perfect-day-container #timeline-container .timeline__step {
            align-items: baseline; } }
        @media (max-width: 500px) {
          #perfect-day-container #timeline-container .timeline__step {
            padding: 10px 0; } }
        #perfect-day-container #timeline-container .timeline__step:before {
          content: '';
          min-width: 30px;
          height: 30px;
          border-radius: 40px;
          border: solid 6px #dc3545;
          background-color: #dc3545;
          margin: 0 30px 0 0; }
          @media (min-width: 1200px) {
            #perfect-day-container #timeline-container .timeline__step:before {
              margin: 0 0 30px 0; } }
          @media (max-width: 500px) {
            #perfect-day-container #timeline-container .timeline__step:before {
              min-width: 20px;
              height: 20px;
              margin-left: 7px; } }
      #perfect-day-container #timeline-container .timeline__title {
        display: inline-block;
        font-size: 12px;
        line-height: 15px;
        text-align: center;
        color: #737373;
        line-height: 15px;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content; }
        @media (max-width: 1199px) {
          #perfect-day-container #timeline-container .timeline__title {
            text-align: left; } }
        @media (max-width: 500px) {
          #perfect-day-container #timeline-container .timeline__title {
            text-align: left;
            text-align: initial; } }
    #perfect-day-container #timeline-container .timeline-hour {
      font-size: 12px;
      line-height: 15px;
      text-align: center;
      color: #ea1c2d;
      font-weight: bold; }
      @media (max-width: 1200px) {
        #perfect-day-container #timeline-container .timeline-hour {
          text-align: left;
          text-align: initial; } }
  #perfect-day-container .perfect-day-title {
    text-align: center;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    margin-top: 50px;
    color: #ea1c2d;
    width: 100%; }
    #perfect-day-container .perfect-day-title div {
      margin-top: 10px;
      color: #929292; }
    @media (max-width: 500px) {
      #perfect-day-container .perfect-day-title {
        font-size: 18px; }
        #perfect-day-container .perfect-day-title div {
          font-size: 14px; } }

#footer-container {
  margin-top: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 80px;
  width: 100%;
  left: 0px;
  position: fixed;
  bottom: 0px;
  background-color: white;
  box-shadow: 1px 0px 11px lightgrey;
  flex-wrap: wrap; }
  @media (max-width: 960px) {
    #footer-container {
      height: 260px;
      justify-content: center;
      align-items: center;
      padding: 15px 25px; } }
  #footer-container > div:not(.alerts-container) {
    width: 33.3%; }
    @media (max-width: 960px) {
      #footer-container > div:not(.alerts-container) {
        width: 100%; } }
  #footer-container .alerts-container {
    width: 100%; }
    #footer-container .alerts-container .alert {
      margin: 0;
      margin-top: 5px; }
  #footer-container .tariff {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    text-align: left; }
    @media (max-width: 960px) {
      #footer-container .tariff {
        text-align: center; } }
    #footer-container .tariff .price-per-person {
      width: 100%;
      text-align: inherit;
      margin-bottom: 5px; }
  #footer-container .datepicker-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end; }
    #footer-container .datepicker-container > span {
      width: 250px;
      color: #dc3545;
      font-weight: bold;
      margin-top: 5px;
      cursor: pointer; }
    @media (max-width: 960px) {
      #footer-container .datepicker-container {
        justify-content: inherit;
        align-items: center; } }
  #footer-container .amount {
    font-weight: bold;
    font-size: 20px;
    line-height: 16px;
    color: #ea1c2d;
    text-align: inherit; }
  #footer-container .basis {
    font-size: 11px; }
  #footer-container .button-primary {
    min-width: 250px;
    margin-top: 5px; }
  #footer-container .alert-text {
    max-width: 365px;
    font-size: 10px; }
  #footer-container .num-travelers-container {
    margin-bottom: 5px; }
    @media (max-width: 426px) {
      #footer-container .num-travelers-container > div {
        margin-right: 0; } }
  @media (max-width: 600px) {
    #footer-container {
      justify-content: center; }
      #footer-container .button-primary {
        width: 100%; } }

.readBtn {
  background: none !important;
  border: none;
  padding: 0 !important;
  font-family: arial, sans-serif;
  color: #ea1c2d;
  text-decoration: underline;
  cursor: pointer;
  margin-left: 0; }

#share-container {
  margin-top: 20px;
  color: #ea1c2d;
  margin-bottom: 210px; }
  #share-container button:not(:last-child) {
    margin-right: 10px; }

#booking-view {
  margin-top: 30px; }
  #booking-view .profile-container {
    text-align: left; }
    #booking-view .profile-container .heading {
      font-size: 12px;
      font-weight: 600; }
    #booking-view .profile-container .content {
      display: flex;
      align-items: center;
      margin: 10px 0;
      background-color: #f4f4f4;
      border-radius: 5px;
      padding: 12px; }
    #booking-view .profile-container .info {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-left: 13px; }
      #booking-view .profile-container .info span {
        font-weight: 500;
        font-size: 12px;
        color: #eb0029;
        text-decoration: underline;
        cursor: pointer; }
  #booking-view .details-container .detail-item {
    padding: 20px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #f0f0f0; }
    #booking-view .details-container .detail-item:last-child {
      border-bottom: none; }
    #booking-view .details-container .detail-item label {
      font-size: 12px;
      font-weight: 600;
      margin-bottom: 0; }
    #booking-view .details-container .detail-item span {
      font-size: 12px; }
    #booking-view .details-container .detail-item.price span {
      font-size: 18px;
      font-weight: 700; }
    #booking-view .details-container .detail-item.price .per-person-container {
      font-size: 10px;
      font-weight: normal; }
    #booking-view .details-container .detail-item .num-travelers-container > div:first-child {
      margin-right: 0;
      margin-bottom: 5px; }
  #booking-view .details-container .detail-input {
    display: flex;
    align-items: center;
    justify-content: space-between; }
    #booking-view .details-container .detail-input label {
      font-size: 12px;
      font-weight: 600; }
    #booking-view .details-container .detail-input input {
      width: 150px;
      font-size: 14px;
      font-weight: 700; }
      #booking-view .details-container .detail-input input:focus {
        box-shadow: none;
        border-color: #eb0029; }
    #booking-view .details-container .detail-input:not(:last-child) {
      margin-bottom: 15px; }
    #booking-view .details-container .detail-input #email {
      min-width: 237px; }

.terms-conditions-link {
  text-decoration: none;
  color: #dc3545;
  font-weight: bold; }
  .terms-conditions-link:hover {
    color: #dc3545;
    text-decoration: none;
    font-size: 14px; }

/* Itinerary */
.itinerary-day-detail {
  display: none; }

.itinerary-day-detail.selected {
  display: block; }

.itinerary-day-box {
  background-color: transparent !important;
  font-family: inherit;
  color: inherit !important;
  font-weight: 700 !important;
  font-size: 11px !important;
  background: none;
  border: none !important;
  box-shadow: unset !important; }

.itinerary-day-box.active {
  background-color: transparent !important;
  color: #eb0029 !important;
  border: none;
  font-weight: 700; }

.itinerary-day-box.active:focus {
  border: none !important; }

.message {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin-bottom: 10px; }

.message.received {
  flex-direction: row-reverse; }

.message .message-text {
  width: 100%;
  padding: 20px;
  text-align: left;
  border-radius: 10px; }

.message.sent .message-text {
  color: white;
  background-color: #ff9f9f;
  border-bottom-right-radius: 0; }

.message.received .message-text {
  background-color: #f0f0f0;
  border-bottom-left-radius: 0; }

.chat-container {
  display: flex;
  flex-wrap: wrap;
  position: fixed;
  bottom: 10px;
  left: 0;
  padding: 10px 20px;
  width: 100%;
  justify-content: center; }
  @media screen and (max-width: 1000px) {
    .chat-container {
      width: 91%; } }
  @media screen and (max-width: 600px) {
    .chat-container {
      width: 88%; } }

.chat-input-container {
  width: 80%; }
  @media screen and (max-width: 600px) {
    .chat-input-container {
      width: 78%; } }
  @media screen and (max-width: 400px) {
    .chat-input-container {
      width: 70%; } }

.not-found {
  flex-flow: row wrap;
  align-items: center; }
  .not-found .header {
    font-size: 50px; }
  .not-found .error-code {
    margin-top: -90px;
    font-size: 250px;
    font-weight: bold; }
  .not-found .error-message {
    font-size: 30px; }
  @media (max-width: 580px) {
    .not-found .error-code {
      margin-top: -52px;
      font-size: 200px; } }
  @media (max-width: 480px) {
    .not-found .error-code {
      margin-top: -52px;
      font-size: 150px; } }
  @media (max-width: 380px) {
    .not-found .error-message {
      font-size: 20px; }
    .not-found .error-code {
      margin-top: -7px;
      font-size: 100px; }
    .not-found .header {
      font-size: 30px; } }

body {
  font-family: 'Montserrat', sans-serif !important;
  font-size: 14px !important;
  color: black !important;
  min-height: calc(100% - 56px) !important;
  top: 0px !important; }

.App {
  text-align: center; }
  .App select {
    background-color: #f7f7f7;
    border: none; }
    .App select:focus {
      background-color: #f7f7f7;
      border: none; }

.container {
  min-height: calc(100vh - 56px);
  margin-top: 56px;
  padding: 30px 20px 15px 20px !important; }

.title {
  font-weight: 700;
  align-self: flex-start;
  margin-bottom: 30px;
  font-size: 22px; }

.App .heJwJj,
.gKyapV {
  background-color: #eb0029; }

.App .dsKVpZ,
.App .dyHccB:hover {
  color: #eb0029; }

.App .custom-datepicker {
  background: #ea1c2d;
  border-radius: 50px;
  border: none;
  min-width: 250px;
  height: 50px;
  cursor: pointer;
  color: white;
  font-weight: bold;
  outline: none;
  padding: 0 20px;
  text-align: center; }
  .App .custom-datepicker::-moz-placeholder, .App .custom-datepicker::-moz-placeholder {
    color: white;
    text-align: center;
    opacity: 1; }
  .App .custom-datepicker::placeholder, .App .custom-datepicker::-moz-placeholder {
    color: white;
    text-align: center;
    opacity: 1; }
  .App .custom-datepicker.white-scheme {
    background-color: white;
    color: #ea1c2d;
    text-align: center;
    border: solid;
    height: 40px;
    margin-top: 5px; }
    .App .custom-datepicker.white-scheme::-webkit-input-placeholder {
      color: #ea1c2d; }
    .App .custom-datepicker.white-scheme::-moz-placeholder {
      color: #ea1c2d; }
    .App .custom-datepicker.white-scheme:-ms-input-placeholder {
      color: #ea1c2d; }
    .App .custom-datepicker.white-scheme::-ms-input-placeholder {
      color: #ea1c2d; }
    .App .custom-datepicker.white-scheme::placeholder {
      color: #ea1c2d; }

.font-small {
  font-size: 12px !important; }

.font-regular {
  font-size: 14px !important; }

.text-capitalized {
  text-transform: capitalize; }

.profile-photo {
  border-radius: 100%;
  background-size: cover;
  background-position: top; }
  .profile-photo.small {
    width: 53px;
    height: 53px; }

.App button {
  outline: 0;
  box-shadow: none;
  font-weight: 600;
  font-size: 14px;
  cursor: pointer; }
  .App button:hover, .App button:active {
    box-shadow: none; }
  .App button.icon-button i {
    margin-right: 5px; }

button.button-primary {
  color: white;
  padding: 15px;
  /* TODO: color variables */
  background-color: #eb0029;
  border: none;
  border-radius: 50px;
  width: 100%; }
  button.button-primary:hover {
    border: none;
    background-color: #eb0029; }
  button.button-primary:active {
    background-color: #eb0029 !important;
    border: none; }

button.button-secondary {
  color: #eb0029;
  padding: 15px;
  background-color: white;
  border: solid #eb0029;
  border-radius: 50px;
  width: 100%; }
  button.button-secondary:hover {
    background-color: #eb0029;
    color: white; }

.App button.btn-primary {
  outline: 0 !important;
  box-shadow: none !important;
  background: #ea1c2d;
  border-radius: 20px;
  border: none;
  width: 140px;
  height: 38px;
  margin-top: 30px; }
  .App button.btn-primary:active, .App button.btn-primary:hover {
    background-color: #eb0029 !important;
    border: none; }
  .App button.btn-primary.inactive {
    color: #fff;
    background-color: #6c757d; }
    .App button.btn-primary.inactive:active, .App button.btn-primary.inactive:hover {
      background-color: #6c757d !important;
      border: none; }

input.form-input {
  border: none;
  border-bottom: solid 1px #e5e5e5;
  width: 100%;
  border-radius: 0;
  font-size: 12px; }

input.form-input:focus {
  outline: none;
  border: none;
  border-bottom: solid 1px #050505;
  box-shadow: none;
  color: #050505; }

input.form-input::-webkit-input-placeholder {
  color: #cccccc; }

input.form-input::-moz-placeholder {
  color: #cccccc; }

input.form-input:-ms-input-placeholder {
  color: #cccccc; }

input.form-input::-ms-input-placeholder {
  color: #cccccc; }

input.form-input::placeholder {
  color: #cccccc; }

.input-group-prepend {
  display: flex;
  align-items: center;
  border: none;
  color: #cccccc;
  padding-left: 5px; }

.user-input {
  text-align: left;
  margin: 20px 0; }
  .user-input:first-child {
    margin-top: 10px; }
  .user-input label {
    font-weight: 600; }
  .user-input select,
  .user-input input {
    font-weight: 500;
    font-size: 12px;
    width: 100%;
    height: 42px;
    padding-left: 12px;
    padding-right: 12px; }
    .user-input select:focus,
    .user-input input:focus {
      box-shadow: none; }
  .user-input input {
    padding-left: 12px;
    padding-right: 12px;
    background-color: #f7f7f7;
    border: none;
    border-radius: 5px; }
    .user-input input:focus {
      background-color: #f7f7f7; }
  .user-input textarea {
    width: 100%;
    height: 100px;
    font-size: 12px;
    font-weight: 500;
    color: #050505;
    background-color: #f7f7f7;
    border: none;
    border-radius: 5px;
    padding: 13px; }
    .user-input textarea:focus {
      outline: none; }
  .user-input .bday-container {
    display: flex;
    justify-content: flex-start;
    width: 70%; }
    .user-input .bday-container input {
      height: 42px;
      width: 84px;
      text-align: center;
      margin-right: 5%; }

