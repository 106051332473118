#new-trip {
	#step-5 {
		#spinner-container {
			height: 100%;
		}

		&.main-container {
			flex-direction: column;
			min-width: 888px;

			@media (max-width: 1000px) {
				height: auto;
				min-width: unset;
			}

			.options-container {
				justify-content: center;
			}

			.main-title-container {
				width: 100%;
				align-items: center;
				border: none;
			}

			.options-container {
				width: 100%;
				padding: 0;

				.user-input {
					width: 100%;
				}
			}
		}
	}
}
