.message {
	display: flex;
	justify-content: flex-end;
	align-items: flex-end;
	margin-bottom: 10px;
}

.message.received {
	flex-direction: row-reverse;
}

.message .message-text {
	width: 100%;
	padding: 20px;
	text-align: left;
	border-radius: 10px;
}

.message.sent .message-text {
	color: white;
	background-color: #ff9f9f;
	border-bottom-right-radius: 0;
}

.message.received .message-text {
	background-color: #f0f0f0;
	border-bottom-left-radius: 0;
}

.chat-container {
	display: flex;
	flex-wrap: wrap;
	position: fixed;
	bottom: 10px;
	left: 0;
	padding: 10px 20px;
	width: 100%;
	justify-content: center;
	@media screen and (max-width: 1000px) {
		width: 91%;
	}
	@media screen and (max-width: 600px) {
		width: 88%;
	}
}
.chat-input-container {
	width: 80%;
	@media screen and (max-width: 600px) {
		width: 78%;
	}
	@media screen and (max-width: 400px) {
		width: 70%;
	}
}
