#step-4 {
	.dates-container {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;

		@media (max-width: 800px) {
			margin: auto;
			height: 100%;
			padding: 11px;
			flex-direction: column;
		}

		.no-dates-checkbox .btn-travel {
			border: none;
			padding: 20px;
			border-radius: 20px;
			color: #ffffff;
			background-color: #ea1c2d;
			width: 265.02px;
			text-align: justify;

			@media (max-width: 500px) {
				margin: auto;
			}

			&.inactive {
				background-color: #e5e5e5;
				color: #929292;
			}
		}
	}

	.options-container {
		input {
			padding: 15px;
			width: 265px;
			height: 50px;
			background-color: #e5e5e5;
			color: #929292;
			border: none;
			border-radius: 10px;
			font-weight: 600;

			&.filled {
				background-color: #dc3545;
				color: white;
			}
		}

		@media (max-width: 800px) {
			width: 100%;
		}
	}
}
