.auth-container {
	display: flex;
	justify-content: center;
	flex-direction: column;
	padding: 0 30px;
	margin-top: 33px;
}

.brand-header {
	background-image: url('../../assets/brand_header_background.png');
	background-size: cover;
	height: 135px;
	display: flex;
	justify-content: center;
}

.brand-header img {
	margin-top: 37px;
	height: 40px;
}

.auth-divider {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin: 18px 0;
}

.auth-divider-line {
	width: 42%;
}

.auth-divider-o {
	font-family: 'Open Sans';
	font-weight: normal;
}

.button-auth {
	border: none;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 15px;
	font-size: 12px;
	font-weight: bold;
	border-radius: 10px;
	width: 100%;
	cursor: pointer;
	background: linear-gradient(-8.52deg, #ffffff 0%, #eeeeee 100%);
	box-shadow: -1px 1px 6px rgba(0, 0, 0, 0.07);
	border-radius: 5px;
}

.button-auth img {
	margin-right: 10px;
	height: 18px;
}

.auth-form {
	margin-bottom: 30px;
}

.auth-fields {
	margin: 4px 0;
}

.link-primary {
	color: #eb0029;
	font-weight: 700;
	cursor: pointer;
}
