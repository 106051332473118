/* Itinerary */
.itinerary-day-detail {
	display: none;
}

.itinerary-day-detail.selected {
	display: block;
}

.itinerary-day-box {
	background-color: transparent !important;
	font-family: inherit;
	color: inherit !important;
	font-weight: 700 !important;
	font-size: 11px !important;
	background: none;
	border: none !important;
	box-shadow: unset !important;
}

.itinerary-day-box.active {
	background-color: transparent !important;
	color: #eb0029 !important;
	border: none;
	font-weight: 700;
}

.itinerary-day-box.active:focus {
	border: none !important;
}
