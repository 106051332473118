#step-1 {
	.country {
		width: 150px;
		height: 100px;
		background-color: white;
		border-radius: 10px;
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;
		&:hover {
			box-shadow: 0px 0px 7px #ea1c2d;
		}
		@media (max-width: 500px) {
			width: 100px;
			height: 100px;
			background-size: contain;
			background-repeat: round;
			font-size: 14px;
		}
		p {
			margin: auto;
			font-family: Montserrat;
			font-style: normal;
			font-weight: bold;
			font-size: 18px;
			text-align: center;
			color: white;
			@media (max-width: 500px) {
				font-size: 14px;
			}
		}
	}

	.country-box {
		width: 150px;
		height: 100px;
		margin-bottom: 10px;
		background-color: white;
		border-radius: 10px;
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;

		&.active {
			border: 2px solid #ea1c2d;
		}

		&.inactive {
			border: none !important;
			-webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
			filter: grayscale(100%);
		}

		p {
			margin: auto;
			font-family: Montserrat;
			font-style: normal;
			font-weight: bold;
			font-size: 18px;
			text-align: center;
			color: white;
			@media (max-width: 500px) {
				font-size: 14px;
			}
		}

		@media (max-width: 500px) {
			width: 100px;
			height: 100px;
			background-size: cover;
			background-repeat: no-repeat;
			font-size: 14px;
		}

		@media (max-width: 350px) {
			width: 100%;
		}
	}
}
