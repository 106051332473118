#step-2 {
	.city-container {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		width: 60%;
		height: calc(300px - 80px);
		padding-left: 50px;

		@media (max-width: 900px) {
			width: 60%;
			padding-left: 0;
		}

		@media (max-width: 600px) {
			width: 100%;
		}
	}

	.city-box {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.2);
		border-radius: 15px;
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;
		cursor: pointer;

		&:hover {
			box-shadow: 0px 0px 7px #ea1c2d;
		}

		&.active {
			border: 2px solid #ea1c2d;
		}

		&.inactive {
			-webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
			filter: grayscale(100%);
		}

		p {
			margin: auto;
			font-family: Montserrat;
			font-style: normal;
			font-weight: bold;
			font-size: 18px;
			text-align: center;
			color: white;
		}
	}
}
