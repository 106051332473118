#profile-edit {
	margin-top: 35px;

	form {
		flex-direction: column;
	}

	.edit-fields {
		margin-top: 30px;
	}

	.profile-photo {
		position: relative;

		.upload-photo-icon {
			position: absolute;
			right: 0;
			bottom: 0;
			height: 43px;
			width: 43px;
			display: flex;
			align-items: center;
			justify-content: center;
			background-color: #eb0029;
			border-radius: 100%;
			color: white;
		}
	}

	.example-day-entry {
		display: flex;
		margin-bottom: 10px;

		input[type='time'] {
			margin-right: 10px;
			max-width: 100px;
			cursor: text;
		}
	}

	.lifestyle-container {
		ul {
			padding: 0;
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
			width: 100%;
		}

		.sortable-lifestyle-pic {
			width: 32%;
			height: 300px;
			margin: 5px;

			@media (max-width: 767px) {
				width: calc(50% - 10px);
				height: calc(50vw - 10px);
			}
		}
	}
}
