// Profile
.car-type-box.active {
	background-color: gray;
}

#profile-view {
	margin-top: 35px;

	.profile-desc {
		margin-top: 15px;
		display: flex;
		flex-direction: column;

		.city {
			font-size: 14px;
			color: #eb0029;
		}

		.country {
			font-size: 12px;
			color: #a0a0a0;
		}

		.name {
			font-weight: 900;
			font-size: 22px;
			margin-top: 10px;
		}

		.bio {
			font-size: 16px;
			margin-top: 16px;
			color: #a0a0a0;
			text-align: justify;
			text-align-last: center;
			white-space: pre-line;
		}

		.user-profile-field {
			text-align: left;

			label {
				align-self: flex-start;
				font-size: 12px;
				font-weight: 600;
			}

			p {
				font-size: 16px;
				color: #a0a0a0;
				text-align: left;
				white-space: pre-line;
			}
		}
	}

	#lifestyle-photos {
		text-align: left;

		label {
			font-size: 12px;
			font-weight: 600;
		}
	}

	.lifestyle-grid {
		display: flex;
		flex-wrap: wrap;

		.lifestyle-pic {
			width: calc(33% - 10px);
			height: 30vw;
			margin: 0 5px 10px 5px;
			background-size: cover;
			background-position: center;

			&:last-child {
				margin-right: 0;
			}
		}
	}

	#profile-footer {
		position: sticky;
		bottom: -2px;
		background-color: white;
		padding-bottom: 15px;
	}

	.tariff {
		text-align: right;
		padding: 25px 0;

		.amount {
			font-weight: 900;
			font-size: 18px;
		}

		.basis {
			font-weight: 500;
			font-size: 10px;
		}
	}
}
