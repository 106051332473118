#booking-view {
	margin-top: 30px;

	.profile-container {
		text-align: left;

		.heading {
			font-size: 12px;
			font-weight: 600;
		}

		.content {
			display: flex;
			align-items: center;
			margin: 10px 0;
			background-color: #f4f4f4;
			border-radius: 5px;
			padding: 12px;
		}

		.info {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			margin-left: 13px;

			span {
				font-weight: 500;
				font-size: 12px;
				color: #eb0029;
				text-decoration: underline;
				cursor: pointer;
			}
		}
	}

	.details-container {
		.detail-item {
			padding: 20px 0;
			display: flex;
			align-items: center;
			justify-content: space-between;
			border-bottom: 1px solid #f0f0f0;

			&:last-child {
				border-bottom: none;
			}

			label {
				font-size: 12px;
				font-weight: 600;
				margin-bottom: 0;
			}

			span {
				font-size: 12px;
			}

			&.price {
				span {
					font-size: 18px;
					font-weight: 700;
				}
				.per-person-container {
					font-size: 10px;
					font-weight: normal;
				}
			}

			.num-travelers-container {
				> div:first-child {
					margin-right: 0;
					margin-bottom: 5px;
				}
			}
		}

		.detail-input {
			display: flex;
			align-items: center;
			justify-content: space-between;

			label {
				font-size: 12px;
				font-weight: 600;
			}

			input {
				width: 150px;
				font-size: 14px;
				font-weight: 700;

				&:focus {
					box-shadow: none;
					border-color: #eb0029;
				}
			}

			&:not(:last-child) {
				margin-bottom: 15px;
			}

			#email {
				min-width: 237px;
			}
		}
	}
}

.terms-conditions-link {
	text-decoration: none;
	color: #dc3545;
	font-weight: bold;
	&:hover {
		color: #dc3545;
		text-decoration: none;
		font-size: 14px;
	}
}
