@import url('https://fonts.googleapis.com/css?family=Montserrat:400,500,600,700,800|Open+Sans&display=swap');

// OVERALL app styles

body {
	font-family: 'Montserrat', sans-serif !important;
	font-size: 14px !important;
	color: black !important;
	min-height: calc(100% - 56px) !important;
	top: 0px !important;
}

// body {
// 	margin: 0;
// 	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
// 		'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
// 		'Helvetica Neue', sans-serif;
// 	-webkit-font-smoothing: antialiased;
// 	-moz-osx-font-smoothing: grayscale;
// }

// code {
// 	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
// 		monospace;
// }

.App {
	text-align: center;

	select {
		background-color: #f7f7f7;
		border: none;

		&:focus {
			background-color: #f7f7f7;
			border: none;
		}
	}
}

.container {
	min-height: calc(100vh - 56px);
	margin-top: 56px;
	padding: 30px 20px 15px 20px !important;
}

.title {
	font-weight: 700;
	align-self: flex-start;
	margin-bottom: 30px;
	font-size: 22px;
}
