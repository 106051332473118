#new-trip {
	form {
		align-items: center;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
	}

	.main-container {
		display: flex;
		justify-content: space-between;
		width: 750px;
		min-height: 300px;
		background: #ffffff;
		box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.2);
		border-radius: 15px;
		padding: 40px 60px;

		@media (max-width: 900px) {
			justify-content: center;
			flex-wrap: wrap;
			width: 750px;
		}
		@media (max-width: 600px) {
			padding: 30px 45px;
		}

		@media (max-width: 350px) {
			padding: 20px;
		}

		.main-title-container {
			display: flex;
			flex-direction: column;
			width: 40%;
			justify-content: center;
			align-items: flex-start;
			border-right: solid;

			@media (max-width: 900px) {
				width: 100%;
				flex-direction: inherit;
				border-right: none;
				align-items: center;
				margin-bottom: 15px;
			}
			@media (max-width: 400px) {
				margin-top: 10px;
			}

			.title-container {
				text-align: initial;
				padding-right: 20px;

				@media (max-width: 900px) {
					width: auto;
					padding: 0;
				}

				h2 {
					font-weight: bold;
					font-size: 24px;
					line-height: 29px;

					@media (max-width: 900px) {
						font-size: 18px;
						margin: auto;
						line-height: 22px;
					}
				}
			}

			.step-container {
				margin-bottom: 10px;
				margin-right: 15px;

				@media (max-width: 900px) {
					margin-bottom: 0;
				}

				.step {
					display: flex;
					justify-content: center;
					align-items: center;
					width: 40px;
					height: 40px;
					background: white;
					border-radius: 50%;
					border-style: solid;
					border-color: #ea1c2d;
				}

				span {
					font-family: Montserrat;
					font-style: normal;
					font-weight: bold;
					font-size: 24px;
					line-height: 29px;
					text-align: center;
					color: #ea1c2d;
				}
			}
		}

		.options-container {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			width: 60%;
			padding-left: 50px;
			align-items: center;

			@media (max-width: 800px) {
				width: 100%;
				justify-content: space-evenly;
				padding-left: 0;
			}
		}

		.btn-travel {
			font-family: Montserrat;
			font-style: normal;
			font-weight: 500;
			font-size: 15px;
			border: none;
			width: 234px;
			border-radius: 20px;
			color: #ffffff;
			background-color: #ea1c2d;
		}
	}

	.progress-bar-container {
		display: flex;
		justify-content: center;
		margin-bottom: 30px;
		width: 100%;
		border-radius: 50px;

		.progress {
			background-color: #000;
			width: 340px;
			border-radius: 50px;
			height: 35px;

			.progress-bar {
				border-radius: 50px;
			}
		}

		.bg-danger {
			background-color: #dc3545 !important;
		}
	}

	.progress-status {
		position: absolute;
		color: white;
		display: flex;
		width: 340px;
		justify-content: flex-end;
		margin-left: -17px;
		margin-top: 7px;

		@media (max-width: 399px) {
			margin-left: -30px;
		}
	}
}

.btn-container {
	width: 100%;

	.btn-primary.inactive:first-child {
		margin-right: 20px;
	}
}

#new-path-container {
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
	max-width: none;
}
