// GENERAL classes

.font-small {
	font-size: 12px !important;
}

.font-regular {
	font-size: 14px !important;
}

.text-capitalized {
	text-transform: capitalize;
}

// TODO: abstract to profilePhoto component
.profile-photo {
	border-radius: 100%;
	background-size: cover;
	background-position: top;

	&.small {
		width: 53px;
		height: 53px;
	}
}
