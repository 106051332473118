#new-trip {
	#step-3 {
		&.main-container {
			min-height: 460px;
		}

		.number-people-container {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-around;
			align-items: center;
			width: 60%;
			height: calc(453px - 80px);
			padding-left: 50px;

			@media (max-width: 900px) {
				width: 60%;
				padding-left: 0;
			}

			@media (max-width: 600px) {
				width: 100%;
			}

			.number-people-box {
				margin-top: 5px;
				display: flex;
				width: 100%;
				height: 62px;
				border-radius: 10px;
				background-color: black;
				font-weight: bold;
				font-size: 18px;
				line-height: 22px;
				color: #ffffff;
				padding: 10px;
				background-size: cover;
				background-repeat: no-repeat;
				z-index: 1;
				cursor: pointer;

				span {
					text-transform: capitalize;
				}

				&:hover {
					box-shadow: 0px 0px 7px #ea1c2d;
				}

				&.active {
					border: 2px solid #ea1c2d;
				}

				&.inactive {
					-webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
					filter: grayscale(100%);
				}
			}
		}

		.description {
			display: none;
			margin-top: -15px;
			padding: 20px 15px 15px 15px;
			background: #eeeeee;
			font-size: 10px;
			font-style: italic;
			line-height: 12px;
			text-align: left;
			border-bottom-left-radius: 10px;
			border-bottom-right-radius: 10px;

			&.active {
				display: block;
			}
		}

		.inputs-container {
			display: flex;
			justify-content: space-around;
			width: 100%;
			margin: 10px 0;
			font-style: normal;
			font-size: 24px;

			.number-input {
				display: flex;
				align-items: center;

				span {
					display: flex;
					align-items: center;
					text-align: center;
					color: #ffffff;
					width: 25px;
					height: 25px;
					background-color: #ea1c2d;
					display: inline-grid;
					cursor: pointer;
				}

				.btn-increase {
					border-top-right-radius: 6px;
					border-bottom-right-radius: 6px;
				}

				.btn-decrease {
					border-top-left-radius: 6px;
					border-bottom-left-radius: 6px;
				}

				.btn-increase,
				.btn-decrease {
					-webkit-touch-callout: none; /* iOS Safari */
					-webkit-user-select: none; /* Safari */
					-khtml-user-select: none; /* Konqueror HTML */
					-moz-user-select: none; /* Old versions of Firefox */
					-ms-user-select: none; /* Internet Explorer/Edge */
					user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
				}

				.num-travelers {
					width: 80px;
					height: 25px;
					font-family: Montserrat;
					font-size: 12px;
					text-align: center;
					color: #929292;
					border: none;
				}

				input:disabled {
					background: white;
				}

				input::-webkit-outer-spin-button,
				input::-webkit-inner-spin-button {
					-webkit-appearance: none;
					margin: 0;
				}

				/* Firefox */
				input[type='number'] {
					-moz-appearance: textfield;
				}
			}
		}
	}
}
