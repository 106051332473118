.ambassador-result-card {
	position: relative;
	margin-top: 60px;
	padding: 20px;
	width: 100%;
	height: calc(95vh - 80px);
	box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.07);
	border-radius: 25px;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	flex-direction: column;

	button {
		font-size: 9px;
		min-width: 100px;
		line-height: 11px;
	}

	.card-info-body {
		width: 100%;
		height: calc(50% - 90px);
		display: flex;
		justify-content: space-around;
		flex-wrap: wrap;
	}

	@media screen and (min-width: 550px) {
		width: 275px;
	}
}

.card-photo-container {
	position: absolute;
	top: 0;
	width: 100%;
	height: 48%;
	cursor: pointer;

	// TODO: abstract to ProfilePhoto component
	.photo-profile {
		width: 100%;
		height: 100%;
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
		border-top-left-radius: 25px;
		border-top-right-radius: 25px;
		background-color: white;
	}
}

#top-score-badge {
	display: flex;
	align-items: center;
	position: absolute;
	top: -30px;
	left: 0;
	color: #ffe600;
	background-color: #000;
	padding: 5px 15px;
	padding-bottom: 50px;
	border-top-left-radius: 7px;
	border-top-right-radius: 7px;
	z-index: -1;

	svg {
		font-size: 20px;
		margin-right: 5px;
	}

	div {
		font-size: 9px;
		font-weight: bold;
	}
}

.card-info {
	display: flex;
	width: 100%;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: center;
	font-size: 10px;

	.card-headline {
		width: 100%;
		display: flex;
		justify-content: space-between;

		> span {
			font-size: 18px;
			font-weight: bold;
		}

		.redknot-verified-badge {
			display: flex;
			align-items: center;

			span {
				font-size: 11px;
				font-weight: normal;
				margin-left: 5px;
				line-height: 20px;
			}
		}
	}

	.card-data {
		display: flex;
		justify-content: space-between;
		width: 100%;
		border-left: 0.5px solid #dddddd;
		border-right: 0.5px solid #dddddd;
		margin-bottom: 10px;

		.info-box {
			width: 50%;
			display: flex;
			align-items: center;
			padding-left: 6px;
			color: #929292;
			line-height: 20px;

			&:not(:last-child) {
				border-right: 0.5px solid #dddddd;
			}

			span {
				font-size: 8.5px;
				font-weight: normal;
				display: flex;
				align-items: center;
				line-height: 11px;

				img {
					margin-right: 5px;
				}
			}
		}
	}
}

.interests-container {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	text-align: left;
	width: 100%;
	border: 1px solid #ea1c2d;
	border-radius: 10px;
	border-top-left-radius: 0;
	padding: 7px 10px;
	margin-bottom: 10px;
	height: auto;
	max-height: 70px;
	align-self: flex-start;

	> span {
		font-weight: 600;
		font-size: 10px;
		color: #ea1c2d;
	}

	p {
		font-size: 9px;
		line-height: 11px;
		margin: 0;
	}
}

.card-info-footer {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	width: 100%;
	border-top: 0.5px solid #dddddd;
	padding-top: 15px;

	button {
		width: 100px;
	}

	.pricing {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		font-size: 9px;
		margin-bottom: 10px;

		.price-total {
			font-size: 14px;
			font-weight: bold;
			text-align: left;
			color: #737373;
			line-height: initial;
		}

		.price-detail {
			font-weight: normal;
			font-size: 9px;
			color: black;
			text-align: left;
		}
	}
}
