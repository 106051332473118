.carousel.carousel-slider {
	width: 100%;
	margin-top: -30px;
	position: absolute;
	height: 400px;
	left: 0;
	@media (max-width: 451px) {
		margin-top: -85px;
	}
}

.carousel-container {
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
	height: 400px;
}
