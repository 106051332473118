.num-travelers-container {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;

	> div:first-child {
		margin-right: 5px;
	}

	.number {
		background-color: white;
		width: 80px;
		height: 25px;
		font-family: Montserrat;
		font-size: 12px;
		text-align: center;
		color: #929292;
		border: none;
	}

	span {
		align-items: center;
		text-align: center;
		color: #fff;
		width: 25px;
		height: 25px;
		background-color: #ea1c2d;
		display: inline-grid;
		cursor: pointer;
	}

	.btn-increase {
		border-top-right-radius: 6px;
		border-bottom-right-radius: 6px;
	}

	.btn-decrease {
		border-top-left-radius: 6px;
		border-bottom-left-radius: 6px;
	}

	.btn-increase,
	.btn-decrease {
		-webkit-touch-callout: none; /* iOS Safari */
		-webkit-user-select: none; /* Safari */
		-khtml-user-select: none; /* Konqueror HTML */
		-moz-user-select: none; /* Old versions of Firefox */
		-ms-user-select: none; /* Internet Explorer/Edge */
		user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
	}
}
