/* Interests */
button.interest-box {
	color: black;
}
button.interest-box.selected {
	color: red;
}

#interests-grid {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;

	.interest-box {
		@media screen and (max-width: 995px) {
			width: calc(50% - 14px);
		}

		@media screen and (min-width: 600px) {
			width: calc(32% - 9px);
		}

		@media screen and (min-width: 1000px) {
			width: 159px;
		}
	}

	button {
		color: #eb0029;
		border: none;
		background-color: white;
		box-shadow: -1px 1px 8px rgba(0, 0, 0, 0.09);
		font-size: 12px;
		margin: 7px;
		padding: 20px;
		width: calc(24% - 60px);
		border-radius: 5px;

		&.selected {
			background-color: #eb0029;
			color: white;
		}
	}

	.interest-title {
		display: flex;
		justify-content: center;
		margin-top: 5%;
		align-items: center;

		.step-container {
			margin-right: 2%;

			.step {
				display: flex;
				justify-content: center;
				align-items: center;
				width: 40px;
				height: 40px;
				background: white;
				border-radius: 50%;
				border-style: solid;
				border-color: #ea1c2d;
			}

			span {
				font-family: Montserrat;
				font-style: normal;
				font-weight: bold;
				font-size: 24px;
				line-height: 29px;
				text-align: center;
				color: #ea1c2d;
			}
		}

		.title-container {
			width: 60%;
			text-align: initial;

			h2 {
				font-weight: bold;
				font-size: 24px;
				line-height: 29px;
			}
		}
	}
}
