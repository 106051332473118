.page-title {
	font-size: 20px;
	font-weight: bold;
}

#nav-brand {
	width: 185px;
	cursor: pointer;
}

#navbar {
	button.navbar-toggler {
		padding: 0;
		margin: 0 !important;

		span {
			font-size: 20px;
		}
	}

	.agent {
		background-color: darkslateblue;
	}

	nav {
		position: fixed;
		top: 0;
		width: 100%;
		box-shadow: 1px 0px 11px lightgray;
		background-color: white;
		z-index: 3;
	}
	i {
		cursor: pointer;
	}
}

.nav .navbar-toggler-icon {
	/* background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(0, 0, 0, 0)''"); */
}

.navbar-collapse {
	background-color: black;
	margin: 0.5rem -1rem -0.5rem -1rem;
}

nav .navbar-nav .nav-item a.nav-link {
	color: white;
}

nav .navbar-nav .nav-item a.nav-link:focus {
	color: #eb0029;
	font-weight: bold;
}

.nav-link {
	margin: 18px 0;
	cursor: pointer;
}

nav .navbar-nav .nav-item a.nav-link:hover {
	color: #eb0029;
	font-weight: bold;
}

.nav-footer {
	height: 73px;
	display: flex;
	padding: 17px;
	justify-content: space-evenly;
	align-items: center;
	background-color: #1b1b1b;
}

.nav-footer .nav-item {
	width: 50%;
}

.nav-footer .nav-item:nth-child(2) {
	width: 50%;
	border-left: solid 1px #2c2c2c;
}

nav .navbar-nav .nav-footer a.nav-link {
	color: #eb0029;
	font-weight: bold;
}

/* Navigation end */
