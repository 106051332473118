input.form-input {
	border: none;
	border-bottom: solid 1px #e5e5e5;
	width: 100%;
	border-radius: 0;
	font-size: 12px;
}

input.form-input:focus {
	outline: none;
	border: none;
	border-bottom: solid 1px #050505;
	box-shadow: none;
	color: #050505;
}

input.form-input::placeholder {
	color: #cccccc;
}

.input-group-prepend {
	display: flex;
	align-items: center;
	border: none;
	color: #cccccc;
	padding-left: 5px;
}

.user-input {
	text-align: left;
	margin: 20px 0;

	&:first-child {
		margin-top: 10px;
	}

	label {
		font-weight: 600;
	}

	select,
	input {
		font-weight: 500;
		font-size: 12px;
		width: 100%;
		height: 42px;
		padding-left: 12px;
		padding-right: 12px;

		&:focus {
			box-shadow: none;
		}
	}

	input {
		padding-left: 12px;
		padding-right: 12px;
		background-color: #f7f7f7;
		border: none;
		border-radius: 5px;

		&:focus {
			background-color: #f7f7f7;
		}
	}

	textarea {
		width: 100%;
		height: 100px;
		font-size: 12px;
		font-weight: 500;
		color: #050505;
		background-color: #f7f7f7;
		border: none;
		border-radius: 5px;
		padding: 13px;

		&:focus {
			outline: none;
		}
	}

	.bday-container {
		display: flex;
		justify-content: flex-start;
		width: 70%;
		input {
			height: 42px;
			width: 84px;
			text-align: center;
			margin-right: 5%;
		}
	}
}
