#gallery-modal {
	display: flex !important;
	align-items: center;
	justify-content: center;
	height: 100%;

	.modal-dialog {
		text-align: center;
		width: 100%;
	}

	.modal-content {
		background-color: transparent;
		border: none;
	}

	.selected-photo {
		min-height: 500px;
		height: 500px;
		width: 100%;
		margin-bottom: 20px;
		background-position: center;
		background-size: cover;
	}

	.gallery-photo {
		min-width: 100px;
		width: 100px;
		height: 100px;
		background-position: center;
		background-size: cover;
		margin-right: 10px;
	}

	.thumbnail-container {
		width: 100%;
		display: flex;
		overflow-x: scroll;
	}

	.control-container {
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
		font-size: 20px;

		span {
			font-size: 30px;
			padding: 0 10px;
			color: #eb0029;
		}
		.close {
			position: absolute;
			top: 0;
			left: 0;
			margin-left: 90%;
			cursor: pointer;
		}
	}
}
